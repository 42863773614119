'use client';

import { useEffect } from 'react';

import { usePathname } from 'next/navigation';
import { IS_PRODUCTION } from 'services/environment';
import { analytics } from 'services/mixpanel';

export default function Template({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  useEffect(() => {
    if (IS_PRODUCTION) {
      analytics.page();
    }

    // https://github.com/vercel/next.js/issues/11109#issuecomment-751429015
    const path = window.location.hash;

    if (path && path.includes('#')) {
      setTimeout(() => {
        const id = path.replace('#', '');
        const el = window.document.getElementById(id);
        const r = el?.getBoundingClientRect();

        if (!!r) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 600);
    }
  }, [pathname]);

  return <div>{children}</div>;
}
